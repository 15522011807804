import { useEffect, useState, useRef } from 'react'
import { Grid, IconButton, Box } from '@mui/material';
import styles from './index.module.scss';
import FootLayer from '../FootLayer/index';
import useScreen from "../../hooks/screen";
import { Helmet } from 'react-helmet';
import web_event_main_pic from '../../assets/Activity/web-event-main-pic.svg';
import web_event_main_title from '../../assets/Activity/web-event-main-title.svg';
import phone_event_main_pic from '../../assets/Activity/phone-event-main-pic.svg';
import phone_event_main_title from '../../assets/Activity/phone-event-main-title.svg';
import web_event_card_1 from '../../assets/Activity/web-event-card-1.svg';
import web_event_card_2 from '../../assets/Activity/web-event-card-2.svg';
import web_event_card_3 from '../../assets/Activity/web-event-card-3.svg';
import phone_event_card_1 from '../../assets/Activity/phone-event-card-1.svg';
import phone_event_card_2 from '../../assets/Activity/phone-event-card-2.svg';
import phone_event_card_3 from '../../assets/Activity/phone-event-card-3.svg';
import web_event_buying_card from '../../assets/Activity/web-event-buying-card.svg';
import phone_event_buying_card from '../../assets/Activity/phone-event-buying-card.svg';
import web_event_notice_card from '../../assets/Activity/web-event-notice-card.svg';
import phone_event_notice_card from '../../assets/Activity/phone-event-notice-card.svg';
import phone_event_3_gift_card from '../../assets/Activity/phone-event-3-gift-card.svg';
import web_event_3_gift_card from '../../assets/Activity/web-event-3-gift-card.svg';


function ActivityPage() {
  const screenWidth = useScreen();

  const handleLogin = () => {
    if (process.env.REACT_APP_ENV_NAME === "dev") {
      window.open('https://toeic-dev.tageasy.com.tw/activity', '_blank');
    } else {
      window.open('https://toeic.tageasy.com.tw/activity', '_blank');
    }
  }
  return (
    <div style={{ backgroundColor: "#FECB68" }}>
      <Helmet>
        <title>專屬優惠399元，再享好禮三重送</title>
        <meta name="description" content="想準備多益卻不知道從哪開始？現在註冊TAG EASY進行刷題，立即享七天免費核心功能體驗，還有三重好禮等你拿！" />
      </Helmet>
      {
        screenWidth > 600 ?
          <Box className={styles.web_event_main_Box} >
            <div className={styles.web_event_main_title} >
              <img src={web_event_main_title} />
              <div className={styles.TrialButtonBox} onClick={() => handleLogin()}>
                <div>立即加入</div>
              </div>
            </div>
            <div >
              <img src={web_event_main_pic} />
            </div>
          </Box>
          :
          <Box className={styles.phone_event_main_Box} >
            <div className={styles.phone_event_main_title} >
              <img src={phone_event_main_title} />
            </div>
            <div >
              <img src={phone_event_main_pic} />
            </div>
            <div className={styles.TrialButtonBox} onClick={() => handleLogin()}>
              <div>立即加入</div>
            </div>
          </Box>
      }

      {
        screenWidth > 600 ?
          <div>
            <div className={styles.event_3_gift_card_title} >
              <h1>好禮活動辦法</h1>
              <span>現在註冊TAG EASY，立即享七天免費核心功能體驗，還有三重好禮等你拿！</span>
            </div>
            <Box className={styles.web_event_card_Box} >
              <div>
                <img src={web_event_card_1} />
              </div>
              <div>
                <img src={web_event_card_2} />
              </div>
              <div>
                <img src={web_event_card_3} />
              </div>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "30px" }}>
              <div className={styles.TrialButtonBox} style={{ width: "300px", height: "90px" }} onClick={() => handleLogin()}>
                <div style={{ fontSize: "27px" }}>立即參加</div>
              </div>
            </Box>
          </div>

          :
          <div>
            <div className={styles.event_3_gift_card_title} >
              <h1>好禮活動辦法</h1>
              <span>現在註冊TAG EASY，立即享七天免費核心功能體驗，還有三重好禮等你拿！</span>
            </div>
            <Box className={styles.phone_event_card_Box} >
              <div className={styles.phone_event_card_item} >
                <img src={phone_event_card_1} />
              </div>
              <div className={styles.phone_event_card_item} style={{ margin: "40px 0px" }}>
                <img src={phone_event_card_2} />
              </div>
              <div className={styles.phone_event_card_item} >
                <img src={phone_event_card_3} />
              </div>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "30px" }}>
              <div className={styles.TrialButtonBox} style={{ width: "300px", height: "90px" }} onClick={() => handleLogin()}>
                <div style={{ fontSize: "27px" }}>立即參加</div>
              </div>
            </Box>
          </div>

      }

      <Box>
        <div className={styles.event_buying_card_Box} >
          <div className={styles.event_buying_card_title} >
            <h1>首購優惠辦法</h1>
            <span>如何讓多益備考輕鬆又高效？TAG EASY神隊友助你一臂之力！</span>
          </div>
          <div className={styles.event_buying_card_img} onClick={() => handleLogin()}>
            {
              screenWidth > 600 ? <img src={web_event_buying_card} /> : <img src={phone_event_buying_card} />
            }
          </div>
        </div>
        <div className={styles.event_notice_card_Box} >
          <div className={styles.event_notice_card_img} >
            {
              screenWidth > 600 ? <img src={web_event_notice_card} /> : <img src={phone_event_notice_card} />
            }
          </div>
        </div>
      </Box>

      <FootLayer />
    </div>
  )
}

export default ActivityPage;
