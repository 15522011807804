import { Routes, Route } from "react-router-dom";
import HomePage from './components/HomePage/index';
import ToCPage from './components/ToCPage/index';
import ToSPage from './components/ToSPage/index';
import ToBPage from './components/ToBPage/index';
import ActivityPage from './components/ActivityPage/index';
import useGtmTracker from './hooks/gtmTracker';


function App() {
  useGtmTracker();
  return (
    <div>
      <Routes>
        <Route path="" element={<HomePage />} />
        <Route path="*" element={<HomePage />} />
        <Route path="/toeic" element={<ToCPage />} />
        <Route path="/school" element={<ToSPage />} />
        <Route path="/business" element={<ToBPage />} />
        <Route path="/activity/202409" element={<ActivityPage />} />
      </Routes>
    </div>

  )
}

export default App
